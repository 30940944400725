<template>
    <footer class="section footer-area">
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.css">
        <!-- Footer Top -->
        <div class="footer-top ptb_100">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title  text-white text-uppercase mb-2">Apartado legal</h3>
                            <ul>
                                <li class="py-2">
                                    <router-link to="terminos">
                                        <a class="text-white-50" >Términos y condiciones</a>
                                    </router-link>
                                </li>
                                <li class="py-2">
                                    <router-link to="privacidad">
                                        <a class="text-white-50" >Política de privacidad</a>
                                    </router-link>
                                </li>
                                <li class="py-2">
                                    <router-link to="datosPersonales">
                                        <a class="text-white-50" >Política de manejo de datos personales</a>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="col-12 col-sm-6 col-lg-3">
                        <div class="footer-items">
                            <h3 class="footer-title text-white text-uppercase mb-2">Soporte</h3>
                            <ul>
                                <li class="py-2"><a class="text-white-50" href="#">Frequently Asked</a></li>
                                <li class="py-2"><a class="text-white-50" href="#">Terms &amp; Conditions</a></li>
                            </ul>
                        </div>
                    </div> -->
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-white text-uppercase mb-2">Síguenos</h3>
                            <p class="text-white-50 mb-2">
                              ¡Visita nuestras redes sociales y síguenos para enterarte de nuevo contenido!
                            </p>
                            <!-- Social Icons -->
                            <ul class="social-icons list-inline pt-2">
                                <li class="list-inline-item px-1"><a href="https://web.facebook.com/ElectoralTech" target="_blank"><i class="fab fa-facebook"></i></a></li>
                                <li class="list-inline-item px-1"><a href="https://twitter.com/Electoral_Tech" target="_blank"><i class="fab fa-twitter"></i></a></li>
                                <!-- <li class="list-inline-item px-1"><a href="#"><i class="fab fa-google-plus"></i></a></li>-->
                                <li class="list-inline-item px-1"><a href="https://www.instagram.com/Electoral_Tech/" target="_blank"><i class="fab fa-instagram"></i></a></li> 
                                <li class="list-inline-item px-1"><a href="https://bit.ly/3i4Udsk" target="_blank"><i class="fab fa-whatsapp"></i></a></li>
                                <li class="list-inline-item px-1"><a href="https://www.tiktok.com/@electoraltech" target="_blank"><i class='Tiktok fontawesomeicon'></i></a></li>
                                <li class="list-inline-item px-1"><a href="https://t.me/ElectoralTech1" target="_blank"><i class="fab fa-telegram"></i></a></li>
                                <li class="list-inline-item px-1"><a href="https://www.linkedin.com/company/electoral-tech-legal/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-2">
                        <h3 class="footer-title text-uppercase mb-2">
                            <router-link to="/marketplace" class="footer-link">MarketPlace</router-link>
                        </h3>
                        <p>
                            Te brindamos un consejo inteligente y amigable, con nuestros experimentados abogados en materia electoral.
                        </p>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <!-- Footer Items -->
                        <div class="footer-items">
                            <!-- Footer Title -->
                            <h3 class="footer-title text-uppercase mb-2">
                                Acerca de
                            </h3>
                            <ul>
                                <li class="py-2">
                                    <router-link to="/about">
                                        <a class="text-black-50" >¿Quiénes somos?</a>
                                    </router-link>
                                </li>
                                <li class="py-2">
                                    <router-link to="/about">
                                        <a class="text-black-50" >Nuestro objetivo</a>
                                    </router-link>
                                </li>
                                <li class="py-2">
                                    <router-link to="/about">
                                        <a class="text-black-50" >Nuestra ubicación</a>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <!-- Copyright Area -->
                        <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                            <!-- Copyright Left -->
                            <div class="copyright-left text-white-50">&copy; Copyrights 2022 </div>
                            <div class="copyright-right text-white-50"> Certificado INDAUTOR con el número de registro: 03-2021-081711214600-01 </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style>
h3 {
  font-size: clamp(20px,3.2vw,28px);
  color: black ;
}
p {
  font-size: clamp(14px,1.5vw,20px);
  color: black ;
}

.fontawesomeicon::before {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
  
  .Tiktok::before {
   font: var(--fa-font-brands);
    content: ' \e07b';
  }
</style>