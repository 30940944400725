<template>
  <div id="registro" class="modal fade p-0">
    <div class="modal-dialog dialog-animated">
      <div class="modal-content h-100">
        <div class="modal-header" data-dismiss="modal">
          Registro de usuarios <i class="far fa-times-circle icon-close"></i>
        </div>
        <div class="modal-body">
          <form class="row" @submit.prevent="submit">
            <div class="col-12 align-self-center">
              <div class="row">
                <div class="col-12 pb-3">
                  <h2 class="search-title mb-3">Crea tu cuenta</h2>
                  <p>
                    Crea tu cuenta en Electoral Tech & Legal para poder acceder a formatos personalizados instantáneos, además de poder tener acceso a asistencia legal profesional en tiempo real.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 input-group mt-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Nombre"
                    autocomplete="off"
                    :class="{ error: $v.form.nombre.$error }"
                    v-model.trim="$v.form.nombre.$model"
                  />
                </div>
                <div class="col-12 input-group mt-3">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Apellidos"
                    autocomplete="off"
                    :class="{ error: $v.form.apellidos.$error }"
                    v-model="$v.form.apellidos.$model"
                  />
                </div>
                <div class="col-12 input-group mt-3">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Correo"
                    autocomplete="off"
                    :class="{ error: $v.form.correo.$error }"
                    v-model="$v.form.correo.$model"
                  />
                </div>
                <div class="col-12 input-group mt-3">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Escribe tu contraseña"
                    autocomplete="off"
                    :class="{ error: $v.form.pass.$error }"
                    v-model="$v.form.pass.$model"
                  />
                </div>
                <div class="col-12 input-group mt-3">
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Repite tu contraseña"
                    autocomplete="off"
                    :class="{ error: $v.form.passConf.$error }"
                    v-model="$v.form.passConf.$model"
                  />
                </div>
                <div class="col-12 mt-3" v-if="!$v.$invalid">
                  <div class="form-group">
                    <vue-recaptcha
                      ref="recaptcha"
                      @verify="onVerify"
                      :sitekey="key"
                      @expired="onExpired"
                    >
                    </vue-recaptcha>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 input-group align-self-center">
                  <button class="btn btn-bordered mt-3" :disabled="!captcha">
                    Iniciar sesión
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import { mapMutations, mapState } from "vuex";
import { auth, fr, analytics } from "../../../firebase";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "RegistroUsuario",
  components: {
    "vue-recaptcha": VueRecaptcha,
  },
  mounted() {},
  data() {
    return {
      form: {
        nombre: "",
        apellidos: "",
        correo: "",
        pass: "",
        passConf: "",
      },
      captcha: false,
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
      urlLocal: "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/",
    };
  },
  validations: {
    form: {
      nombre: {
        required,
      },
      apellidos: {
        required,
      },
      correo: {
        email,
        required,
      },
      pass: {
        minLength: minLength(5),
        required,
      },
      passConf: {
        required,
        minLength: minLength(5),
        sameAs: sameAs(function () {
          return this.form.pass;
        }),
      },
    },
  },
  computed: {
    ...mapState("generals", ["key"]),
    url() {
      return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd;
    },
  },
  methods: {
    ...mapMutations({
      setUsuario: "auth/setUsuario",
    }),
    onVerify: function (response) {
      if (response) {
        const url = process.env.NODE_ENV === "development" ? this.urlLocal : this.urlProd;
        fetch(url + "validateCaptcha/" + response)
          .then((res) => res.json())
          .then((res) => {
            console.info(res);
            this.captcha = true;
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    },
    onExpired: function () {
      console.log("Expired");
      this.captcha = false;
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset(); // Direct call reset method
    },
    hideModal() {
      window.$("#registro").modal("hide");
    },
    submit(ev) {
      ev.preventDefault();
      this.registrarAuth();
    },
    cleanForm() {
      this.form.nombre = "";
      this.form.apellidos = "";
      this.form.correo = "";
      this.form.pass = "";
      this.form.passConf = "";
      this.$v.form.$reset();
    },
    async registroFR(uidUser, usuario) {
      fr.collection("usuarios")
        .doc(uidUser)
        .set(usuario)
        .then(() => {
          this.setUsuario(usuario);
          this.cleanForm();
          window.$("#registro").modal("hide");
          this.$swal({
            icon: "success",
            title: "Bienvenido, " + usuario.nombre,
          });
          analytics.logEvent("Intento de nuevo registro");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
          this.$swal({
            icon: "error",
            title: "Verifica tu conexión a internet y intenta de nuevo..",
          });
        });
    },

    async registrarAuth() {
      auth
        .createUserWithEmailAndPassword(this.form.correo, this.form.pass)
        .then((resp) => {
          const usuario = {
            nombre: this.form.nombre,
            apellidos: this.form.apellidos,
            correo: this.form.correo,
            fecha_registro: Date.now(),
            tipoUsuario: 0,
          };
          this.registroFR(resp.user.uid, usuario);
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/email-already-in-use":
              this.$swal({
                icon: "info",
                title: "El correo  proporcionado ya esta en uso..",
              });
              break;
            case "auth/invalid-email":
              this.$swal({
                icon: "info",
                title: "Dirección de correo invalida",
              });
              break;
            case "auth/operation-not-allowed":
              this.$swal({ icon: "info", title: "Registro no disponbible" });
              break;
            case "auth/weak-password":
              this.$swal({
                icon: "info",
                title:
                  "La contraseña debe tener mayúsculas, minúsculas y números.",
              });
              break;
          }
        });
    },
  },
};
</script>

<style>
.form-control {
  height: calc(1.5em + 0.75rem + 2px);
}
.error {
  border: 1px solid #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>