export default {
    fechaFormato_2(miliseconds) {
        if (miliseconds) {
            /*
            const fecha = new Date(miliseconds);
            // fecha.setMilliseconds = miliseconds * 1000;
            let mes = "";
            if (fecha.getMonth() + 1 < 10) {
                mes = (fecha.getMonth() + 1).toString().padStart(2, "0");
            } else {
                mes = fecha.getMonth() + 1;
            }

            let dia = '';
            if ( fecha.getDate() < 10){
                dia = (fecha.getDate()).toString().padStart(2, "0");
            } else {
                dia = fecha.getDate();
            }
            return dia + "/" + mes + "/" + fecha.getFullYear();*/
            const date = new Date(miliseconds);
            var fecha = date.toLocaleDateString('en-GB');
            console.log(fecha);
            return fecha
        }
    },
    fechaFormato(miliseconds) {
        if (miliseconds) {
            const date = new Date(miliseconds);
            var fecha = date.toLocaleDateString('en-GB');
            //console.log(fecha);
            return fecha
        }
    }
}