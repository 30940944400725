<!--E-learning-->
<template>
  <div>
    <div class="container">
      <div class="filtros">
        <div class="d-flex justify-content-center" >
          <div class="encabezados">
            <ul class="nav nav-pills mb-5" id="pills-tab" role="tablist">
              <!--
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-all-post" data-toggle="pill" href="#pills-all-post" role="tab"
                    aria-controls="pills-all-post" aria-selected="true" @click="SelectType(0)">
                  Todos
                </a>
              </li>
              -->
              
              <li class="nav-item" role="presentation">
                <a class="nav-link active" id="pills-abogadoArti" data-toggle="pill" href="#pills-abogadoArti" role="tab"
                    aria-controls="pills-abogadoArti" aria-selected="false" @click="SelectType(1)">
                  Boletín LegalTech
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-boletinElec" data-toggle="pill" href="#pills-boletinElec" role="tab"
                    aria-controls="pills-boletinElec" aria-selected="false" @click="SelectType(2)">
                  Boletín Electoral
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-conferencias" data-toggle="pill" href="#pills-conferencias" role="tab"
                    aria-controls="pills-conferencias" aria-selected="false" @click="SelectType(3)">
                  Conferencias
                </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" id="pills-otros" data-toggle="pill" href="#pills-otros" role="tab"
                    aria-controls="pills-otros" aria-selected="false" @click="SelectType(4)">
                  Otros
                </a>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- SPACER -->
    <div class="container " style="height:10px"> </div>

    <div class="text-center" v-if="filter === 3">
      <button class="btn btn-primary" @click="irAYouTubeMagDoJoseOliveros">Canal del Magistrado</button>

      <div class="container " style="height:30px"> </div>

    </div>
    

    <div class="row">
      <div class="col-12 col-md-6" v-for="post in data" :key="post.id">
        <!-- Single Blog -->
        <div class="single-blog res-margin">
          <!-- Blog Thumb -->
          <div class="blog-thumb">
            <img :src="post.image" :alt="post.titulo" />
          </div>
          <!-- Blog Content -->
          <div class="blog-content">
            <!-- Meta Info -->
            <ul class="meta-info d-flex justify-content-between">
              <li>By ElectoralTech & Legal </li>
              <li>
                <a href="#">{{ post.fechaRegistro }}</a>
              </li>
            </ul>
            <!-- Blog Title -->
            <h3 class="blog-title my-3">
              <a href="#">{{ post.titulo }}</a>
            </h3>
            <p>
              {{ post.descripcion }}
            </p>
            <a @click="verEvento(post.link)" class="blog-btn mt-3">
              Ver Más</a
            >
          </div>
        </div>
      </div>
    </div>
    <Pagination
      class="mb-5"
      :pagination="paginationList"
      :posts="flyToNumber"
      :next="next"
      :previous="previous"
    />
  </div>
</template>
<script>
import { fr } from "../../../firebase";
import Pagination from "../Pagination/Pagination_vue";
import fn from "../../../libs/index";

export default {
  name: "BlogOne",
  components: {
    Pagination,
  },
  mounted() {
    /**Este es un comentario para prueba*/
    //this.obtenerPosts(this.filter,true);
    this.obtenerPaginacion();
    this.clickboton();
    
    this.flyToNumber(0);
    
  },
  data() {
    return {
      data: [],
      totalPag: 0,
      paginationList: [],
      limit: 4,
      limit_1:5,
      firstPosts: [],
      lastPost: null,
      idCarrusel: null,
      numCarrusel:0,
      pagActual: 0,
      filter: 0,
      showConferenciasButton: false,
    };
  },
  computed:{

  },
  methods: {
    next() {
      if (this.pagActual + 1 < this.totalPag) {
        //this.pagActual++;
        //this.obtenerPosts(true);
        this.flyToNumber(this.pagActual+1);
        //this.obtenerPaginacion();
        /*
        this.paginationList.forEach((pag) => {
          if (pag.id == this.pagActual) {
            pag.active = true;
          } else {
            pag.active = false;
          }
        });*/
      }
    },
    previous() {
      if (this.pagActual > 0 & this.pagActual<this.totalPag) {
        //this.obtenerPosts(false);
        //this.pagActual--;
        this.flyToNumber(this.numCarrusel-1);
        //this.obtenerPaginacion();
        /*
        this.paginationList.forEach((pag) => {
          if (pag.id == this.pagActual) {
            pag.active = true;
          } else {
            pag.active = false;
          }
        });*/
      }
    },


    async SelectType(id){
      //console.log("SelectedType",id);
      this.data = [],
      this.totalPag= 0,
      //this.paginationList= [],
      //this.limit= this.limit,
      this.firstPosts= [],
      this.lastPost= null,
      //this.pagActual= 0,
      this.filter=id;
      this.obtenerPosts(id,true);
      
      this.flyToNumber(0);
      this.obtenerPaginacion();
      if (id === 3) {
        this.showConferenciasButton = true;
        console.log("Se dio S")
      } else {
        this.showConferenciasButton = false;
        console.log("Se dio N")
      }
    },
    clickboton(){
      document.querySelector('#pills-abogadoArti').click();
      //console.log("Se dio click")
    },

    irAYouTubeMagDoJoseOliveros() {
      // Redirige a la URL de YouTube deseada
      window.location.href = 'https://youtube.com/@magdo.joseoliveros2472?si=6kW-73-ojMLMIs3N';
    },

    async flyToNumber(idCarrusel){

   
      //const fechaAGuardar_2 = Date.now();
      //const fechaAGuardar_3 = Date(Date.now());
      //const fechita = fn.fechaFormato(1670640400000);
      //console.log("Fechita",fechita);
          
      //console.log("pagination_list",Math.round(this.paginationList[this.paginationList.length-1].id),"ThisCarrusel",idCarrusel,"Or",this.paginationList[this.paginationList.length-1].id);
      //if (Math.round(this.paginationList[this.paginationList.length-1].id)<this.limit_1 &Math.round(this.paginationList[this.paginationList.length-1].id)<this.totalPag-this.limit_1) {
        //console.log("GG",this.totalPag-Math.floor(this.limit_1/2),"idCarrusel",idCarrusel);
        if (idCarrusel>Math.floor(this.limit_1/2)-1 & idCarrusel<(this.totalPag-Math.floor(this.limit_1/2))+1) {
        //console.log("EntramosIf");
        this.obtenerPaginacion();
      }
      const querySnapshot = await fr
                      .collection("learning")
                      .orderBy("fechaRegistro", "desc")
                      .where("estatus", "==", 1)
                      .where("filtro","==",this.filter)
                      .get();
      //console.log("flyQuerySnapshot",querySnapshot);
      this.numCarrusel = idCarrusel;
      
      let query = [];
      query = await fr
            .collection("learning")
            .orderBy("fechaRegistro", "desc")
            .where("estatus", "==", 1)
            .where("filtro","==",this.filter)
            .limit(this.limit);

      //console.log("FlyQuery",query);
      if (this.pagActual<this.numCarrusel) {

        if (this.lastPost != null) {
          this.pagActual=this.numCarrusel;
          //console.log("Multiplicación",(this.numCarrusel*(this.limit))-1,"numero",this.numCarrusel);
          this.lastPost = querySnapshot.docs[(this.numCarrusel*(this.limit))-1];
          //console.log("this.lastPost != null",this.lastPost,"ThisNumCarrusel",this.numCarrusel);
          //console.log("FLYLastPost_3a",this.lastPost,"FLYQueryTrue_3a",query.get());
          query = query.startAfter(this.lastPost);
          //console.log("LastPost_3b",this.lastPost,"QueryTrue_3b",query.get());
        }
      } 
      else {
      //console.log("Else",((this.numCarrusel*(this.limit))-1))
      this.pagActual=this.numCarrusel;
      this.lastPost = querySnapshot.docs[(this.numCarrusel*(this.limit))-1];
      if (this.numCarrusel==0)
      {
        this.firstPosts.push(querySnapshot.docs[0]);
        query = query.startAt(this.firstPosts[this.numCarrusel]);
      }
      else{
        query = query.startAfter(this.lastPost);

      }
      //this.firstPosts.push(querySnapshot.docs[this.numCarrusel]);
    }
    /*
    for (let i= 0; i<querySnapshot.docs.length;i++)
      {
        console.log("flyQuerySnapshot_1",i,"-",querySnapshot.docs[i].id);
      }*/
      
      

      //console.log("queryGet",query.get())
      query.get().then((querySnapshot) => {
            if (!querySnapshot.empty) {
              this.lastPost = querySnapshot.docs[querySnapshot.docs.length - 1];
              
              this.firstPosts.push(querySnapshot.docs[0]);
              //console.log("FIRSTPOST-",querySnapshot.docs[0].id,"LASTPOST-",this,this.lastPost.id);

              let temp = [];
              querySnapshot.forEach((doc) => {
                //console.log("Segundoqueryy",doc.id, " => ", doc.data());
                temp.push({
                  id: doc.id,
                  ...doc.data(),
                  fechaRegistro: fn.fechaFormato(doc.data().fechaRegistro),
                });
              });
              this.data = temp;
            } else {
              console.info("colection empty_fly");
            }
          });
          
          this.paginationList.forEach((pag) => {
          if (pag.id == this.numCarrusel) {
            //console.log("pagActive=True");
            pag.active = true;
          } else {
            //console.log("pagActive=False");
            pag.active = false;
          }
        });
       
    },

    async obtenerPaginacion() {
      //console.log("obtener paginacion");
      const dbLearning = await fr.collection("learning");
      const query = dbLearning.orderBy("fechaRegistro","desc").where("estatus", "==", 1).where("filtro","==",this.filter);
      const snapshot = await query.get();      
      this.totalPag = Math.round(snapshot.docs.length / this.limit);
      //console.log("TotalDocumentos",snapshot.docs.length,"Total Carrusel",this.totalPag);
      
      const indexInit = this.numCarrusel-Math.floor(this.limit_1/2);
      const indexEnd = this.numCarrusel+Math.ceil(this.limit_1/2);
      //console.log("IndexInit",indexInit,"IndexEnd",indexEnd,"carrusel",this.numCarrusel);
      let temp = [];
      //for (let index = 0; index < this.totalPag; index++) {
      for (let index = 0; index < this.totalPag; index++) {
        // console.log(index);
        temp.push({
          id: index,
          active: false,
        });
      }

      
    if (indexInit<0 & this.numCarrusel<Math.floor(this.limit_1/2)){
      //console.log("SIIII")
    this.paginationList = temp.slice(0,this.limit_1);
    this.paginationList[0].active = true;
  }
    else{
      //console.log("NOOO")
      this.paginationList = temp.slice(indexInit,indexEnd);
      this.paginationList[Math.floor(this.limit_1/2)].active = true;
        }    
    },

    async obtenerPosts(filtro,band) {
      if(filtro == 0){
        try {
          let query = await fr
            .collection("learning")
            .orderBy("fechaRegistro", "desc")
            .where("estatus", "==", 1)
            .limit(this.limit);
          if (band) {
            if (this.lastPost != null) {
              query = query.startAfter(this.lastPost);
            }
          } else {
            query = query.startAt(this.firstPosts[this.pagActual - 1]);
          }
          query.get().then((snapshot) => {
            this.lastPost = snapshot.docs[snapshot.docs.length - 1];
            this.firstPosts.push(snapshot.docs[0]);

            let temp = [];
            snapshot.forEach((doc) => {
              temp.push({
                id: doc.id,
                ...doc.data(),
                fechaRegistro: fn.fechaFormato(doc.data().fechaRegistro),
              });
            });
            this.data = temp;
          });
        } catch (error) {
          console.warn(error);
        }
      }else{
        try {
          let query = await fr
            .collection("learning")
            .orderBy("fechaRegistro", "desc")
            .where("estatus", "==", 1)
            .where("filtro", "==",  this.filter)
            .limit(this.limit);
          if (band) {
            if (this.lastPost != null) {
              query = query.startAfter(this.lastPost);
            }
          } else {
            query = query.startAt(this.firstPosts[this.pagActual - 1]);
          }
          query.get().then((snapshot) => {
            this.lastPost = snapshot.docs[snapshot.docs.length - 1];
            this.firstPosts.push(snapshot.docs[0]);
            let temp = [];
            snapshot.forEach((doc) => {
              temp.push({
                id: doc.id,
                ...doc.data(),
                fechaRegistro: fn.fechaFormato(doc.data().fechaRegistro),
              });
            });
            this.data = temp;
          });
        } catch (error) {
          console.warn(error);
        }
      }
    },
    verEvento(link) {
      console.log(link);
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
a {
  cursor: pointer !important;
}

 .promo-wrapper {
      cursor: pointer;
  }

  .homepage-3 .single-promo h3 {
    font-size: 18px;
  }
  .active{
    background: none;
  }
  .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #e3d6ff; 
    border-bottom: solid 5px #fc0362;
    color: black;
    font-weight: bold;
  }

  .nav-link{
    font-size: 22px;
  }

</style>
