<template>
    <section class="section breadcrumb-area overlay-dark d-flex align-items-center"
        :style="{ background: `rgba(0, 0, 0, 0) url(${ this.background }) no-repeat fixed center center / cover`} ">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Breamcrumb Content -->
                    <div class="breadcrumb-content text-center">
                        <h2 class="text-white text-uppercase mb-3">E-learning</h2>
                        <h3 class="text-white-50">Conoce los cursos de capacitación y actualización que se imparten en línea y bajo demanda,  así como los eventos académicos (seminarios, conferencias, talleres) sobre los temas más actuales de la materia electoral</h3>
                        <!-- <ol class="breadcrumb d-flex justify-content-center">
                            <li class="breadcrumb-item"><a class="text-uppercase text-white" href="/">Home</a></li>
                            <li class="breadcrumb-item"><a class="text-uppercase text-white" href="#">Blog Pages</a></li>
                            <li class="breadcrumb-item"><a class="text-uppercase text-white" href="#">Blog Grid</a></li>
                            <li class="breadcrumb-item text-white active">Grid 2 Column</li>
                        </ol> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'BlogTwoColumn',
    props: {
        background: String
    }
}
</script>

<style>

</style>