<template>
  <div class="blog">
    <ScrollupSection />
    <div class="main overflow-hidden">
      <HeaderSection />
      <BreadcrumbSection :background="back"/>
      <section id="blog" class="section blog-area ptb_100">
        <div class="container">
          <Blogs />
        </div>
      </section>
      <FooterSection />
      <ModalSearchSection />
      <ModalRegistro />
      <ModalMenuSection />
    </div>
  </div>
</template>

<script>
import ScrollupSection from "../../Scrollup/Scrollup";
import HeaderSection from "../../Header/HeaderOne";
import BreadcrumbSection from "../../Breadcrumb/BlogTwoColumn";
import Blogs from "../BlogOne/BlogOne";
// import Pagination from '../Pagination/Pagination'
import FooterSection from "../../Footer/FooterThree";
import ModalSearchSection from "../../Modal/ModalSearch/ModalSearch";
import ModalRegistro from "../../Modal/ModalSearch/ModalRegistro";
import ModalMenuSection from "../../Modal/ModalMenu/ModalMenu";

export default {
  name: "BlogTwoColumn",
  components: {
    ScrollupSection,
    HeaderSection,
    BreadcrumbSection,
    Blogs,
    // Pagination,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ModalRegistro,
  },
  metaInfo: {
    title: "Electoral Tech & Law | E-learning ",
    description: "Post con contenido revelante en material político-electoral"
  },
  data(){
    return {
      back: "../../assets/fondos/elearning.png"
    }
  }
};
</script>

<style>
</style>